export default function info(
  state = {
    batch: {
      form: {},
      pages: {
        page: 1,
        limit: 10,
      },
    },
    settle: {
      form: {},
      pages: {
        page: 1,
        limit: 10,
      },
    },
    invoiceView: {
      form: {},
      pages: {
        page: 1,
        limit: 10,
      },
    },
    invoiceViewSc: {
      form: {},
      pages: {
        page: 1,
        limit: 10,
      },
    },
    acBatch: {
      form: {},
      pages: {
        page: 1,
        limit: 10,
      },
    },
  },
  action
) {
  switch (action.type) {
    case 'GET':
      return action.queryData;
    case 'SET':
      return { ...state, ...action.queryData };
    default:
      return state;
  }
}
